export function Close({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_532_12897)'>
				<path
					d='M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4L14.6 16L8 22.6L9.4 24L16 17.4L22.6 24L24 22.6L17.4 16L24 9.4Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_532_12897'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
