import {
	Description,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/react'
import { type ReactNode } from 'react'
import Button, { type ButtonStyle } from './button'
import clsx from 'clsx'
import { Close } from '~/icons/close'

export type DialogModalProps = {
	panelDepth: number
	isOpen: boolean

	// fetcher?: FetcherWithComponents<unknown>
	// fetcherData?: { [key: string]: unknown }

	title: string
	description?: string
	children?: ReactNode | ReactNode[]
	panelClassName?: string

	onPrimary: () => void
	onClose: (path: 'secondary' | 'primary') => void

	primaryText: string
	primaryStyle: ButtonStyle
	primaryDisabled?: boolean
	primaryAutoFocus?: boolean

	secondaryText: string
	secondaryStyle: ButtonStyle
	secondaryAutoFocus?: boolean

	closeAutoFocus?: boolean

	panelWrapperClassName?: string
	rootClassName?: string

	disableOverlay?: boolean
	hideCloseButton?: boolean
	hideButtonGroup?: boolean
	hideSecondary?: boolean
}

export default function DialogModal({
	panelDepth,
	isOpen,
	children,
	title,
	description,
	panelClassName,
	onPrimary,
	onClose,
	primaryText,
	primaryStyle,
	primaryDisabled,
	secondaryText,
	secondaryStyle,
	hideSecondary,
	panelWrapperClassName,
	rootClassName,
	disableOverlay,
	hideCloseButton,
	hideButtonGroup,
	primaryAutoFocus,
	secondaryAutoFocus,
	closeAutoFocus
}: DialogModalProps) {
	const bgColor = panelDepth % 2 === 0 ? 'bg-layer-01' : 'bg-layer-02'

	return (
		<Dialog
			as='div'
			role='dialog'
			open={isOpen}
			transition
			className={clsx(
				'relative z-dialog-modal',
				'opacity-100 transition duration-150 ease-out-expo data-[closed]:opacity-0 [&_.panelitem]:translate-y-0 data-[closed]:[&_.panelitem]:translate-y-2'
			)}
			onClose={() => onClose('secondary')}
			onKeyDown={event => {
				if (event.key !== 'Escape') {
					event.stopPropagation()
				} else {
					onClose('secondary')
				}
			}}
		>
			<div
				className={clsx(
					'fixed inset-0 z-dialog-modal overflow-y-auto',
					!disableOverlay && 'bg-background/50',
					rootClassName
				)}
			>
				<div
					className={clsx(
						'pointer-events-auto relative flex h-full items-center justify-center p-4 text-center',
						panelWrapperClassName
					)}
				>
					<DialogPanel
						transition
						className={clsx(
							`panelitem flex max-h-[84%] w-full max-w-[480px] transform flex-col ${bgColor} text-left align-middle shadow-[0_2px_6px_0_rgba(0,0,0,0.2)] transition-all`,
							panelClassName
						)}
					>
						{/* Modal Header */}
						<div className='flex flex-none items-start'>
							<DialogTitle
								as='h5'
								className='grow p-4 text-h5 text-[white]'
							>
								{title}
							</DialogTitle>
							{!hideCloseButton && (
								<Button
									onClick={() => onClose('secondary')}
									$style='ghost'
									$size='medium'
									icon={<Close />}
									iconOnly={true}
									autoFocus={closeAutoFocus}
								/>
							)}
						</div>

						{/* Modal Content */}
						<div className='grow overflow-auto !overflow-x-hidden pb-12'>
							<Description className='px-4 pb-4 text-small text-text-primary'>
								{description}
							</Description>
							<div className='flex flex-col gap-8 px-4'>
								{children}
							</div>
						</div>

						{/* Modal Button Group */}
						<div
							className={clsx(
								'justify-left flex w-full flex-none gap-[1px]',
								hideButtonGroup && 'hidden'
							)}
						>
							<Button
								$size='large'
								$style={secondaryStyle}
								autoFocus={secondaryAutoFocus}
								onClick={() => onClose('secondary')}
								rootClassName={clsx(
									'flex-grow w-full',
									hideSecondary && 'collapse'
								)}
								disabled={false}
							>
								{secondaryText}
							</Button>
							<Button
								$size='large'
								$style={primaryStyle}
								autoFocus={primaryAutoFocus}
								rootClassName='flex-grow w-full'
								disabled={primaryDisabled}
								as='button'
								onClick={() => {
									onPrimary()
									onClose('primary')
								}}
							>
								{primaryText}
							</Button>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	)
}
